<template lang="pug">
  div
    el-card(body-style="padding:0")
      div(slot="header" v-if="can('payments.list')")
        el-form(inline)
          el-form-item
            el-button(type="primary" icon="el-icon-plus" @click="create" :disabled="!can('payments.create')") Yeni kayıt
          el-form-item(label="Tarih aralığı")
            el-date-picker(
              v-model="filters.period"
              type="daterange"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="$store.state.dateranges"
              range-separator="—"
              start-placeholder="Başlangıç"
              end-placeholder="Bitiş"
              clearable
              style="width: 300px"
              @change="fetch(false)"
            )
          el-form-item(label="Ödeme/Gider")
            el-select(v-model="filters.payee_type_id" filterable @change="filters.paymentable_id = null" clearable)
              el-option(v-for="payee in payees" :key="payee.id" :value="payee.id" :label="payee.name")

          el-form-item(:label="paymentables[filters.payee_type_id].name" v-if="filters.payee_type_id && filters.payee_type_id !== 'expense'")
            el-select(v-model="filters.paymentable_id" filterable clearable)
              el-option(
                v-for="c in filteredPayees"
                :key="c.id"
                :label="c.name + (c.hasOwnProperty('company') && c.company ? ' / ' + c.company.name : '')"
                :value="c.id"
              )

          el-form-item(label="Kategori" v-if="filters.payee_type_id && filters.payee_type_id === 'expense'")
            el-select(v-model="filters.paymentable_id" filterable clearable)
              el-option(
                v-for="c in filteredPayees"
                :key="c.id"
                :label="c.name + (c.hasOwnProperty('company') && c.company ? ' / ' + c.company.name : '')"
                :value="c.id"
              )

          el-form-item(label="Kategori" v-if="filters.payee_type_id && filters.payee_type_id !== 'expense'")
            el-select(v-model="filters.type_id" filterable clearable)
              el-option(v-for="c in paymentTypes" :key="c.id" :label="c.name" :value="c.id")

          el-form-item
            el-button-group
              el-button(@click="fetch" :loading="fetching") Getir
              el-button(@click="fetch(true)" icon="el-icon-download") Excel
      el-table(
        :data="payments.data"
        show-summary
        size="mini"
        v-loading="fetching"
        @row-dblclick="edit"
        :summary-method="summary"
        :height="$store.state.windowHeight-154"
        v-if="payments.data.length > 0 && can('payments.list')"
      )
        el-table-column(prop="payee_type.name" label="Ödeme/Gider" sortable width="150px")
        el-table-column(
          prop="paymentable.name"
          :label="filters.payee_type_id ? paymentables[filters.payee_type_id].name : ''"
          sortable
          width="200px"
          show-overflow-tooltip
        )
        el-table-column(prop="amount" label="Tutar" align="right" sortable width="200px")
          template(v-slot="props") {{ props.row.amount }} {{ props.row.currency }}
        el-table-column(prop="type.name" label="Kategori" sortable align="center" width="150px")
          template(v-slot="props"): span.text-success {{ props.row.type.name }}
        el-table-column(prop="user.name" label="Kaydeden" sortable align="center" width="150px")
        el-table-column(prop="created_at" label="Kayıt tarihi" sortable width="150px")
          template(v-slot="props") {{ props.row.created_at|dayjs('DD.MM.YYYY / HH:mm') }}
        el-table-column(prop="note" label="Açıklama" sortable)
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)" :disabled="!can('payments.edit')")
                span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)" :disabled="!can('payments.remove')")
                span.el-icon-delete.text-danger
        
      el-drawer(:visible.sync="modal" :title="payment.id ? 'ÖDEME DÜZENLE' : 'ÖDEME OLUŞTUR'" size="350px" :wrapperClosable="false")
        el-form.pl-15.pr-15(label-position="top")

          el-form-item(label="Ödeme/Gider")
            el-select(v-model="payment.payee_type_id" filterable @change="payment.paymentable_id = null" clearable style="width: 100%")
              el-option(v-for="payee in payees" :key="payee.id" :value="payee.id" :label="payee.name")

          el-form-item(
            :label="payment.payee_type_id ? paymentables[payment.payee_type_id].name : ''"
            v-if="payment.payee_type_id && payment.payee_type_id !== 'expense'"
          )
            el-select(v-model="payment.paymentable_id" filterable clearable style="width: 100%")
              el-option(
                v-for="c in filteredPayeesForm"
                :key="c.id"
                :label="c.name + (c.hasOwnProperty('company') && c.company ? ' / ' + c.company.name : '')"
                :value="c.id"
              )

          el-form-item(label="Kategori" v-if="payment.payee_type_id && payment.payee_type_id === 'expense'")
            el-select(
              :value="payment.paymentable_id"
              @change="payment.paymentable_id = $event; payment.type_id = $event;"
              filterable
              clearable
              style="width: 100%"
            )
              el-option(v-for="c in filteredPayeesForm" :key="c.id" :label="c.name" :value="c.id")

          el-form-item(label="Kategori" v-if="payment.payee_type_id && payment.payee_type_id !== 'expense'")
            el-select(v-model="payment.type_id" filterable clearable style="width: 100%")
              el-option(v-for="c in paymentTypes" :key="c.id" :label="c.name" :value="c.id")

          el-form-item(label="Tutar")
            el-input(v-model="payment.amount")
              el-select(v-model="payment.currency" style="width: 80px" slot="append" filterable)
                el-option(v-for="c in $store.state.currencies" :key="c" :label="c" :value="c")

          el-form-item(label="Açıklama")
            el-input(type="textarea" v-model="payment.note")
        center
          el-button(type="primary" @click="save" :loading="saving" :disabled="!saveable") Kaydet
          el-button(type="text" @click="modal = false") Vazgeç
</template>
<script>
  import collect from 'collect.js'
  import Type from '@/models/Type'
  import User from '@/models/User'
  import Company from '@/models/Company'
  import Payment from '@/models/Payment'
  export default {
    name: 'payments',
    data(){
      return {
        companies: [],
        clients: [],
        payees: [],
        paymentTypes: [],
        expenseTypes: [],
        payments: { data: [] },
        payment: {
          paymentable_id: null,
          paymentable_type: 'user',
          payee_type_id: null,
          company_id: null,
          client_id: null,
          type: null,
          amount: 0,
          currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
          note: ''
        },
        filters: {
          period: [],
          paymentable_id: null,
          payee_type_id: null,
          type_id: null
        },
        fetching: false,
        saving: false,
        modal: false
      }
    },
    created(){
      if(this.can('payments.list')) {
        Company.whereIn('type', ['agency', 'supplier', 'taxi']).get().then(data => {
          this.companies = data;
        })
        User.include(['roles', 'company']).whereIn('roles', ['admin', 'guide', 'dealer', 'taxidriver', 'seller']).get().then(data => {
          this.clients = data;
        })
        Type.whereIn('category', ['payee', 'payment', 'expense']).get().then(data => {
          this.payees = data.filter(a => a.category === 'payee')
          this.paymentTypes = data.filter(a => a.category === 'payment')
          this.expenseTypes = data.filter(a => a.category === 'expense')
        })
        this.fetch()
      }
    },
    methods: {
      async fetch(){
        try{
          this.fetching = true
          this.payments = await Payment.where('payee_type_id', this.filters.payee_type_id)
            .whereIn('created_between', this.filters.period || [])
            .where('paymentable_id', this.filters.paymentable_id)
            .where('type_id', this.filters.type_id)
            .where('branch_id', this.$store.state.branch.id)
            .include(['paymentable', 'payeeType', 'type', 'user'])
            .get()
          this.fetching = false
        }catch(e){
          this.fetching = false
          this.$message.error(e.message)
        }
      },
      create(){
        this.payment = new Payment({
          paymentable_id: null,
          paymentable_type: null,
          branch_id: this.$store.state.branch.id,
          payee_type_id: this.filters.payee_type_id || null,
          type_id: null,
          amount: 0.00,
          currency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD',
          note: ''
        })
        this.modal = true
      },
      async edit({ id }){
        this.payment = await Payment.find(id)
        this.modal = true
      },
      async save(){
        try{
          this.saving = true
          await this.payment.save()
          this.saving = false         
          this.modal = false
          await this.fetch()
        }catch(e){
          this.saving = false
          this.$message.error(e.message)
        }
      },
      async remove(id){
        await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Vazgeç',
          type: 'warning',
          center: true
        })
        try {
          let { data } = await window.axios.delete('/payments/' + id)
          this.fetch(false)
          if(data.deleted){
            this.$message({
              type: 'success',
              message: 'Kayıt silinmiştir',
              center: true,
              closable: true
            })
          }
        } catch(e) {
          this.$message({
            type: 'error',
            message: 'Kayıt silinemedi',
            center: true,
            closable: false
          })
        }
      },
      summary({ columns, data }){
        let sums = collect(data).groupBy('currency')
          .map((g, k) => `${g.sum('amount').toFixed(2)} ${k}`)
          .join(', ')
        return ['', 'Toplam', sums]
      }
    },
    computed: {
      paymentables: function(){
        return collect(this.payees).keyBy('id').all()
      },
      filteredPayees: function(){
        let res = []
        switch(this.filters.payee_type_id){
          case 'agency':
            res = this.companies.filter(c => c.type === 'agency')
            break
          case 'supplier':
            res = this.companies.filter(c => c.type === 'supplier')
            break
          case 'taxi':
            res = this.companies.filter(c => c.type === 'taxi')
            break
          case 'guide':
            res = this.clients.filter(c => c.roles.includes('guide'))
            break
          case 'taxidriver':
            res = this.clients.filter(c => c.roles.includes('taxidriver'))
            break
          case 'dealer':
            res = this.clients.filter(c => c.roles.includes('dealer'))
            break
          case 'seller':
            res = this.clients.filter(c => c.roles.includes('seller'))
            break
          case 'expense':
            res = this.expenseTypes
            break
        }
        return res
      },
      filteredPayeesForm: function(){
        let res = []
        switch(this.payment.payee_type_id){
          case 'agency':
            res = this.companies.filter(c => c.type === 'agency')
            break
          case 'supplier':
            res = this.companies.filter(c => c.type === 'supplier')
            break
          case 'taxi':
            res = this.companies.filter(c => c.type === 'taxi')
            break          
          case 'guide':
            res = this.clients.filter(c => c.roles.includes('guide'))
            break
          case 'taxidriver':
            res = this.clients.filter(c => c.roles.includes('taxidriver'))
            break
          case 'dealer':
            res = this.clients.filter(c => c.roles.includes('dealer'))
            break
          case 'seller':
            res = this.clients.filter(c => c.roles.includes('seller'))
            break
          case 'expense':
            res = this.expenseTypes
            break
        }
        return res
      },
      saveable: function(){
        return this.payment.paymentable_id && this.payment.payee_type_id && this.payment.type_id && this.payment.amount > 0 && this.payment.currency
      }
    }
  }
</script>
